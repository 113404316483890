import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/global/Layout'
import SEO from '../components/global/Seo'
import TopNav from '../components/global/TopNav'
import PeopleCards from '../components/sectionBlocks/PeopleCards'
import ArrowIcon from '../components/icons/ArrowIcon'

const personProfile = ({ data }) => {
  let sectionChapter;
  data.allStrapiChapter.nodes.forEach((chapter) => {
    if (chapter.strapiId === data.strapiPeople.section.chapter) {
      sectionChapter = chapter;
    }
  })

  const sectionLink = `/${sectionChapter.chapter_slug}/${data.strapiPeople.section.section_slug}/`;

	return (
		<Layout>
      <SEO title={data.strapiPeople.name} image={data.strapiPeople.illustration}/>
      <TopNav />
      <div className={`pt64 backgroundBlack`}>
        <div className={`pb64 pt64 pageMargin1Col`}>
          <Link to={'/profile-cards/'} className={`h5`}>
            <i className={`inlineIcon mr8`} style={{transform: 'rotate(90deg)'}}>
              <ArrowIcon />
            </i>
            All Profile Cards
          </Link>
        </div>
        
        <PeopleCards people={[data.strapiPeople]} shareButton={false}/>

        <div className={`pb64 pt128 pageMargin1Col textCenter`}>
          <p className={`h5 pb32`}>From the Under One Flag Online Exhibition</p>
          <Link to={sectionLink} className={`h5`}>
            <p className={`h4 textColorWhite`}>{data.strapiPeople.section.section_title}</p>
            <p className={`h5 pb16 textColorWhite`}>
              <span className={`accentText`}>{sectionChapter.subtitle}: </span>
              {sectionChapter.title}
            </p>
            View In Section
            <i className={`inlineIcon pl4 pr4`} style={{transform: 'rotate(-90deg)'}}>
              <ArrowIcon />
            </i>
          </Link>
        </div>
      </div>
    </Layout>
	)
}

export const query = graphql`
  query PeopleTemplate($person_id: String!) {
    strapiPeople(person_id: { eq: $person_id }) {
      name
      person_id
      body
      illustration {
        alternativeText
        caption
        localFile {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      section {
        chapter
        section_slug
        section_title
      }
    }
    allStrapiChapter {
      nodes {
        strapiId
        chapter_slug
        title
        subtitle
      }
    }
  }
`

export default personProfile
